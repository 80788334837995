import React from 'react';
import StaticLayout from 'components/layouts/static-layout';
import {graphql} from 'gatsby';
import {Formik} from 'formik';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {actions} from 'state/notifications';
import {ColorBox, Container, Form, P, Row, Wave} from 'components/wrappers';
import {HEADER_BG_COLOR, HERO_BG_COLOR, HERO_COLOR} from 'assets/theme';
import gql from 'graphql-tag';
import Button from 'components/button/button';
import {shape, stringValidator} from 'utils/validation';
import {useMutation} from '@apollo/react-hooks';
import Input from 'components/formik/input';
import TextArea from 'components/formik/textarea';

const MUTATION_CREATE_ENQUIRY = gql`
  mutation CreateEnquiry($enquiryCreateInput: EnquiryCreateInput!) {
    createEnquiry(enquiryCreateInput: $enquiryCreateInput) {
      id
      message
    }
  }
`;

const initialFormValues = {
  firstName: '',
  lastName: '',
  company: '',
  email: '',
  message: '',
};

const validationSchema = shape({
  firstName: stringValidator('First name'),
  lastName: stringValidator('Last name'),
  company: stringValidator('Company'),
  email: stringValidator('Email'),
  message: stringValidator('Message'),
});

const Contact = ({data, location, addNotification}) => {
  const {title, siteUrl} = data.site.siteMetadata;
  const [createEnquiry] = useMutation(MUTATION_CREATE_ENQUIRY);

  return (
    <StaticLayout
      {...{
        bgColor: HERO_BG_COLOR,
        location,
        seo: {title: `${title} about`, description: `Contact page for ${siteUrl}`},
      }}
    >
      <ColorBox bgColor={HERO_BG_COLOR} color={HERO_COLOR}>
        <Container hero>
          <h1>Get in touch</h1>
          <P l>Please enter your details in the form below</P>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} />
      <ColorBox bgColor={HEADER_BG_COLOR} color={HERO_COLOR}>
        <Container heroPadding>
          <Formik
            initialValues={{...initialFormValues}}
            validationSchema={validationSchema}
            onSubmit={async (values, actions) => {
              const {setSubmitting, resetForm} = actions;

              setSubmitting(true);
              try {
                await createEnquiry({
                  variables: {
                    enquiryCreateInput: {
                      ...values,
                    },
                  },
                });
                setSubmitting(false);
                addNotification({
                  type: 'success',
                  message: 'Your message has been sent!',
                });
                resetForm({...initialFormValues});
              } catch (ex) {
                addNotification({
                  type: 'danger',
                  message: ex.message,
                });
                setSubmitting(false);
              }
            }}
            validateOnBlur
          >
            {props => {
              const {values, handleSubmit, isSubmitting} = props;
              const {firstName, lastName, company, email, message} = values;
              return (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <div className="col-lg-6">
                      <Input
                        id="firstName"
                        value={firstName}
                        label="First name"
                        placeholder="First name"
                        primary
                      />
                      <Input
                        id="lastName"
                        value={lastName}
                        label="Last name"
                        placeholder="Last name"
                        primary
                      />
                      <Input
                        id="email"
                        value={email}
                        label="Email"
                        placeholder="Email"
                        primary
                      />
                      <Input
                        id="company"
                        value={company}
                        label="Company"
                        placeholder="Company"
                        primary
                      />
                    </div>
                    <div className="col-lg-6">
                      <TextArea
                        id="message"
                        value={message}
                        label="Message"
                        placeholder="Message"
                        primary
                        height="373px"
                      />
                    </div>
                  </Row>
                  <Button
                    type="submit"
                    primary
                    className="float-right"
                    disabled={isSubmitting}
                  >
                    Send message!
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </ColorBox>
      <Wave bgColor={HERO_BG_COLOR} flip />
      <br />
    </StaticLayout>
  );
};

Contact.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  addNotification: PropTypes.func.isRequired,
};

export default connect(null, {
  addNotification: actions.addNotification,
})(Contact);

export const GATSBY_QUERY = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`;
